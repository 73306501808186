//
// Mixins
//

@use "sass:math";

@mixin kt-spinner-size($size) {
  &:before {
    width: kt-get($kt-spinner-config, size, $size);
    height: kt-get($kt-spinner-config, size, $size);
    margin-top: -1 * math.div(kt-get($kt-spinner-config, size, $size), 2);
  }

  &.kt-spinner--center {
    &:before {
      left: 50%;
      margin-left: -1 * math.div(kt-get($kt-spinner-config, size, $size), 2);
    }
  }

  &.kt-spinner--left {
    &:before {
      left: 0;
      right: auto;
    }
  }

  &.kt-spinner--right {
    &:before {
      left: auto;
      right: 0;
    }
  }
}

@mixin kt-spinner-skin($color) {
  &:before {
    border: 2px solid $color;
    border-right: 2px solid transparent;
  }
}
