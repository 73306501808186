// Global config
@import "./assets/sass/config";
@import "./assets/sass/global/layout/config";

.mat-table__wrapper {
  overflow-x: hidden;

  .mat-table {
    min-width: auto;

    /*table-layout: fixed;*/
    thead {
      background-color: #eaeaea;

      tr.mat-header-row {
        height: 46px;

        .mat-header-cell {
          color: #1a1a1a;

          button {
            color: #333333;
          }
        }
      }
    }

    tbody {
      tr.mat-footer-row,
      tr.mat-row {
        height: 58px;
        border-bottom: 1px solid #a5a8ad;

        &:hover {
          background-color: $kt-table-tr-hover-color;
        }
      }

      tr td {
        cursor: pointer;
        color: #636363;
        font-weight: 400;
        font-size: 12px;

        &:not(:last-child) {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 210px;
          transition: all 0.2s ease;

          @media (max-width: 768px) {
            max-width: 200px;
          }

          &:hover {
            // overflow: visible;
            // white-space: normal;
            // word-wrap: break-word;
          }
        }
      }
    }

    @media (max-width: 768px) {
      .mat-column-checkbox,
      .mat-column-expand {
        //width: 60px;
        width: 3%;
      }

      .responsive {
        display: none;
      }
    }

    &.readonly {
      tbody tr td {
        cursor: auto;
      }
    }
  }
}

$paginator-border-color: #b7b7b7;
$paginator-text-color: #a6a6a6;

.mat-table__bottom {
  background-color: $kt-content-bg-color !important;
  padding-top: 10px;

  .mat-paginator {
    background-color: $kt-content-bg-color !important;
  }

  .mat-paginator-range-label {
    display: none;
    width: 70px;
    text-align: right;
  }

  .mat-paginator-navigation-first,
  .mat-paginator-navigation-previous,
  .mat-paginator-navigation-next,
  .mat-paginator-navigation-last {
    width: 25px;
    height: 27px;
    line-height: 27px;

    .mat-paginator-icon {
      width: 26px;
    }
  }

  .btn_custom-paging-container {
    padding: 0 10px;

    .mat-icon-button {
      border: 1px solid $paginator-border-color;
      border-radius: 5px;
      width: 27px;
      height: 27px;
      line-height: 27px;
      margin: 0 3px;
      font-weight: bold;
      transition: all 0.25s ease;
      color: $paginator-text-color;

      &:disabled,
      &[disabled] {
        border-color: kt-get($kt-state-colors, brand, base);
        border-width: 2px;
        color: kt-get($kt-state-colors, brand, base);
      }
    }
  }

  .mat-paginator-page-size {
    .mat-paginator-page-size-label {
      display: none;
    }

    .mat-paginator-page-size-select {
      margin: 15px 4px 0 4px;
      width: 65px;

      .mat-form-field-flex {
        border: 1px solid $paginator-border-color;
        border-radius: 5px;

        .mat-form-field-infix {
          padding: 0.6375em;
          text-align: right;

          .mat-select-value {
            .mat-select-value-text {
              margin-right: 5px;
              color: $paginator-text-color;
            }
          }

          .mat-select-arrow {
            color: $paginator-text-color;
          }
        }
      }
    }
  }
}

table {
  &.table-bordered {
    border: 1px solid kt-get($kt-table-colors, border);

    tr td {
      border: 0;
      border-bottom: 1px solid kt-get($kt-table-colors, border);
    }
  }

  .highlighted {
    background-color: #f9f9f9;
  }

  tfoot {
    &.tfoot-hover {
      cursor: pointer;
      text-align: center;

      tr td {
        color: kt-get($kt-state-colors, brand, base) !important;
      }

      tr:hover {
        background-color: #edf4f9;
      }
    }
  }

  &.summary-table {
    tr {
      td {
        font-size: 12px;

        &:nth-child(even) {
          color: #595959;
          text-align: right;

          &:not(:last-child),
          &:nth-child(2) {
            border-right: 1px solid kt-get($kt-table-colors, border);
          }
        }

        &:nth-child(odd) {
          color: #999999;
        }
      }
    }
  }
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-color: #e8e8e8;
}

/** For datatable responsivity **/
.entity-details-info-column {
  overflow: hidden !important;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.mat-table__wrapper .mat-table tr.mat-row.entity-details-info-row {
  height: 0 !important;
}

.mat-table__wrapper .mat-table tr.entity-default-row td {
  border-bottom-width: 0;
}

.entity-details-info-column .entity-details-info-column-data {
  display: flex;

  .entity-details-info-column-data-key {
    color: #222;
    font-weight: 500;
    width: 160px;
  }
}

.mat-table__wrapper .mat-table th.expand-entity-header {
  display: none;

  @media (max-width: 768px) {
    display: table-cell;
  }
}

.mat-table__wrapper .mat-table td.expand-entity-info {
  display: none;

  @media (max-width: 768px) {
    display: table-cell;
  }

  button {
    i {
      font-size: 16px;
    }

    &.collapse-info {
      display: none;
    }
  }

  &.entity-expanded-row {
    button.expand-info {
      display: none;
    }

    button.collapse-info {
      display: inline-block;
    }
  }
}

.mat-table__wrapper .mat-table tbody tr:not(.entity-details-info-row) td.mat-column-actions,
.mat-table__wrapper .mat-table thead tr:not(.entity-details-info-row) th.mat-column-actions {
  text-align: right;
}

.mat-checkbox-frame {
  background-color: #fff !important;
  border-width: 1px !important;
  border-color: #bdbdbd !important;
}

.mat-radio-outer-circle {
  border-color: #ccc;
  border-width: 1px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  background-color: kt-get($kt-state-colors, brand, base) !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #fff !important;
}

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle {
  border-color: transparent;
}

.mat-raised-button {
  box-shadow: none !important;
  height: calc(1.5em + 1.3rem + 2px);
  font-weight: 400 !important;
  font-size: 12px;
}

.btn-secondary {
  border: 1px solid #eee !important;
}

.mat-badge-content {
  min-width: 30px;
  border-radius: 10px;
}

.badge {
  font-weight: 600;
  padding: 7px !important;
  border-radius: 3px;
  display: inline-block;
}

.mat-form-field {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      border: 1px solid #ebebeb;
      border-radius: 2px;
    }

    .mat-form-field-infix {
      border: none;
      padding: 0.8375em;

      .mat-form-field-label-wrapper {
        display: none;
      }
    }

    .mat-form-field-underline {
      background-color: transparent;
      visibility: hidden;

      .mat-form-field-ripple {
        display: none;
      }
    }
  }

  &.no-wrapper-padding {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }

    .mat-form-field-underline {
      height: 0 !important;
      visibility: hidden !important;
    }
  }

  &.mat-focused:not(.mat-form-field-invalid) {
    .mat-form-field-flex {
      border: 1px solid kt-get($kt-state-colors, brand, base);
    }
  }

  &.mat-focused.mat-form-field-invalid {
    .mat-form-field-flex {
      border: 1px solid kt-get($kt-state-colors, danger, base);
    }
  }

  &.searchable {
    .mat-form-field-infix {
      padding-left: 2.5em;
    }

    .search-icon {
      position: absolute;
      left: 9px;

      svg {
        width: 18px;
        fill: #bfbfbf;
        position: relative;
        top: -2px;
      }
    }
  }
}

.search-and-submit-form {
  border: 1px solid #ebebeb;
  border-radius: 2px;
  padding: 0.8375em 2.5em;
  position: relative;

  input {
    font-weight: 400;
    font-size: 13px;

    &::placeholder {
      color: #d2d2d2;
    }
  }

  .search-icon,
  .submit-icon {
    position: absolute;
    width: 18px;
  }

  .search-icon {
    left: 10px;

    svg {
      fill: #bfbfbf;
    }
  }

  .submit-icon {
    right: 10px;
    cursor: pointer;

    i {
      color: kt-get($kt-state-colors, brand, base);
    }
  }
}

.checkboxs-row {
  height: 76.89px;
  padding-bottom: 1.25em;
}

.datatable-search {
  margin: 0 10px;
  width: 300px;

  @media (max-width: 768px) {
    // width: 200px;
    width: 50%;
  }

  .search-wrapper {
    position: relative;

    .search-icon {
      position: absolute;
      left: 11px;
      top: 0.71rem;
      color: #bfbfbf;
      font-size: 15px;

      &:has(+ input.amortization) {
        top: 0.4rem;
      }

      svg {
        width: 20px;
        fill: #aeaeae;
      }
    }

    input {
      padding-left: 2.5em;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05) !important;
      border: 1px solid #ebebeb;
      color: #58615c;
      font-size: 15px;

      &::placeholder {
        color: #aeaeae;
        font-weight: 300;
      }
    }
  }
}

.datatable-selected-filters-wrapper {
  min-width: 64%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .datatable-selected-filter-wrapper {
    margin: 2px 5px;
    position: relative;

    .datatable-selected-filters.mat-raised-button {
      line-height: 31px;
      height: 31px;
    }

    .datatable-selected-filters-remove.mat-icon-button {
      width: 15px;
      height: 15px;
      line-height: 15px;
      position: absolute;
      top: -13px;
      right: -8px;
      z-index: 30000;
      color: #1868c8 !important;
    }
  }
}

.mat-accordion .mat-expansion-panel {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

  &:last-of-type {
    border-bottom-right-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  &:first-of-type {
    border-top-right-radius: 2px !important;
    border-top-left-radius: 2px !important;
  }

  &.for-filters {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;

    .mat-expansion-panel-header {
      padding: 0 30px;
    }
  }

  &.mat-expansion-panel-spacing {
    margin: 0 !important;
  }

  &:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    background: none !important;
  }

  .mat-expansion-panel-header {
    .mat-expansion-panel-header-title {
      color: #262626;
      font-size: 14px;
    }
  }

  .mat-expansion-indicator::after {
    color: #acacac;
    border-width: 0 1px 1px 0;
    padding: 4px;
  }

  .mat-radio-label-content {
    color: #999999;
    font-weight: 700;
  }

  .mat-radio-checked .mat-radio-label-content {
    color: kt-get($kt-state-colors, brand, base);
  }
}

.datatable-filters-dropdown {
  .dropdown-menu {
    width: 350px !important;

    .kt-nav__head {
      padding: 0.55rem 25px;

      .reset-btn {
        border-radius: 20px;
        padding: 0.4rem 1rem;
        font-size: 0.875rem;
      }

      .head-title {
        font-weight: bold;
        display: flex;
        align-items: center;

        .datatable-filters-icon {
          margin-right: 5px;
        }

        .active-filters-count {
          background-color: kt-get($kt-state-colors, brand, base);
          color: #fff;
          width: 30px;
          text-align: center;
          height: 23px;
          line-height: 23px;
          border-radius: 12px;
          margin-left: 15px;
          font-size: 13px;
        }
      }
    }
  }
}

#kt_header {
  margin-left: 20px;
}

.kt-header-base-light .kt-header {
  border-bottom: none;
}

.kt-header-base-light .kt-header .kt-header__topbar .kt-header__topbar-item:hover .kt-header__topbar-icon,
.kt-header-base-light .kt-header .kt-header__topbar .kt-header__topbar-item.show .kt-header__topbar-icon,
.kt-header-base-light
  .kt-header
  .kt-header__topbar
  .kt-header__topbar-item:hover.kt-header__topbar-item--user
  .kt-header__topbar-user,
.kt-header-base-light
  .kt-header
  .kt-header__topbar
  .kt-header__topbar-item.show.kt-header__topbar-item--user
  .kt-header__topbar-user {
  background-color: transparent !important;
}

.datatable-filters-icon {
  fill: kt-get($kt-state-colors, brand, base);
}

.kt-form.kt-form--group-seperator-dashed .kt-form__group:last-child {
  border-bottom: none;
}

.kt-portlet.datatable-view {
  .kt-portlet__head {
    .kt-portlet__head-label {
      width: 50%;

      @media (max-width: 768px) {
        width: 25%;
      }
    }

    .kt-portlet__head-title {
      // max-width: 35%;
      color: #1a1a1a;
      font-weight: 600;
    }

    .kt-portlet__head-toolbar {
      button {
        @media (max-width: 768px) {
          height: calc(1.5em + 1.3rem - 2px);
          padding: 0px 13px;
        }
      }
    }
  }

  &.datatable-view-no-filters {
    .kt-portlet__head {
      .kt-portlet__head-title {
        max-width: 100%;
      }
    }
  }

  .kt-portlet__body {
    padding: 0;

    .mat-table__wrapper .mat-table th.mat-header-cell:nth-of-type(2),
    .mat-table__wrapper .mat-table td.mat-cell:nth-of-type(2) {
      padding-left: 24px;
    }
  }
}

.kt-portlet.add-edit-entity {
  overflow: hidden;

  .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title {
    font-size: 18px;
    font-weight: 500;
    color: #1a1a1a;
  }

  .mat-input-label {
    margin-bottom: 0.3rem;
    color: #4d4d4d;
    font-size: 12px;
  }

  .kt-portlet__body {
    padding: 25px 40px;

    .mat-radio-group {
      .mat-radio-button {
        .mat-radio-label-content {
          color: #999999;
          font-size: 13px;
          font-weight: 400;
        }

        &.mat-radio-checked {
          .mat-radio-label-content {
            color: #808080;
          }
        }
      }
    }

    .mat-form-field {
      .mat-form-field-infix {
        color: #000 !important;
        background-color: $kt-mat-form-field-background-color;

        .mat-select-value-text,
        .mat-option-text {
          color: #000 !important;
        }
      }

      &.sm-form-field {
        .mat-form-field-wrapper {
          padding-bottom: 0;

          .mat-form-field-infix {
            padding: 0.6375em 0.8375em !important;
            font-size: 12px;
          }

          .mat-form-field-underline {
            height: 0;
            visibility: hidden;
          }
        }
      }
    }
  }
}

.entity-details-display-page {
  .kt-portlet {
    margin-bottom: 25px;

    .kt-portlet__head {
      border-bottom: none;
      padding: 0 18px;

      .kt-portlet__head-title {
        color: #1a1a1a;
        font-size: 1.27rem;
        font-weight: bold;
      }
    }

    .kt-portlet__body {
      margin-bottom: 10px;

      .table {
        border-right: transparent;
        border-left: transparent;

        thead tr {
          th {
            background-color: #f9f9f9;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            border-top: 1px solid kt-get($kt-table-colors, border);
          }
        }

        tr {
          td,
          th {
            font-size: 12px;

            &:first-child {
              padding-left: 18px;
            }
          }
        }

        &.ellipses-overflow {
          td:not(.actions),
          th:not(.actions) {
            white-space: nowrap;
            max-width: 85px;
            transition: all 0.2s ease;

            &:hover {
              // overflow: visible;
              // white-space: normal;
              // word-wrap: break-word;
            }
          }
        }
      }

      .portlet-actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 15px 18px 10px;

        a,
        button {
          display: block;
          margin-right: 10px;
          font-size: 12px;
        }
      }
    }
  }
}

.kt-portlet.shared-component .kt-portlet__body,
.kt-portlet.shared-component .kt-portlet__foot {
  padding: 0 !important;
}

.kt-portlet__foot.entity__footer {
  border-top: none !important;
  margin-top: -30px !important;
  text-align: right !important;
  padding: 0 40px 25px !important;
}

.kt-subheader {
  background-color: $kt-content-bg-color !important;
  box-shadow: none !important;

  .kt-subheader__main {
    .kt-subheader__breadcrumbs {
      .breadcrumbs-separator {
        margin: 0 8px;
        color: #959595;
      }

      .kt-subheader__breadcrumbs-link {
        color: #959595 !important;

        &.kt-subheader__breadcrumbs-link--active {
          color: #4d4d4d !important;
          font-weight: bold;
        }
      }
    }
  }
}

.kt-header-mobile__logo img {
  width: 65px;
}

.kt-aside-light .kt-aside {
  box-shadow: none !important;
}

.kt-aside__brand-logo img {
  height: 30px;
}

.kt-aside-menu .kt-menu__nav {
  padding: 35px 0;
}

/* aside links */
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon {
  font-size: 1.7rem;
  color: #bbbbbb !important;
}

.kt-aside-light .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon,
.kt-aside-light .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
  color: #808080;
}

.kt-aside-light
  .kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__link
  .kt-menu__link-text {
  color: #999999;
}

.kt-aside-light .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
  font-weight: 500;
}

.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-svg {
  flex: 0 0 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 0;

  img {
    width: 25px;
  }

  svg {
    width: 30px;
    fill: #808080;
  }
}

.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-svg {
  img {
    width: 35px;
  }

  svg {
    fill: #bfbfbf;
    width: 35px;
  }
}

.kt-aside-light .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active .kt-menu__link-svg svg,
.kt-aside-light .kt-aside-menu .kt-menu__nav > .kt-menu__item--submenu.kt-menu__item--here .kt-menu__link-svg svg,
.kt-aside--minimize
  .kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-svg
  svg,
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item--submenu.kt-menu__item--here .kt-menu__link-svg svg {
  fill: kt-get($kt-state-colors, brand, base);
}

.kt-aside-light .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link,
.kt-aside-light
  .kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(
    .kt-menu__item--active
  ):hover
  > .kt-menu__link,
.kt-aside-light
  .kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(
    .kt-menu__item--active
  ):hover
  > .kt-menu__link,
.kt-aside-light
  .kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__link,
.kt-aside-light .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link,
.kt-aside-light .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link {
  background-color: transparent !important;
}

.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-bullet {
  opacity: 0;
  margin-right: 5px;
}

.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link {
  min-height: 30px;
}

.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link {
  min-height: 50px;
}

.kt-footer {
  background-color: $kt-content-bg-color !important;
}

.kt-content {
  @media (min-width: 1025px) {
    padding: 0px 0 25px !important;
  }
}

.header__topbar-item-global--search--wrapper {
  width: 100%;
  position: relative;

  & > .announcing {
    font-size: 24px;
    position: absolute;
    overflow: hidden !important;
    text-wrap: none;
    width: 50%;
    margin-top: 10px;
    @media screen and (max-width: 1300px) {
      width: 40%;
    }
    @media screen and (max-width: 1150px) {
      width: 30%;
    }
  }

  .kt-header__topbar-item-global--search {
    display: flex;
    align-items: center;

    /** Hide global search input in mobile **/
    @media (max-width: kt-get($kt-media-breakpoints, md)) {
      display: none;
    }

    .kt-quick-search {
      width: 400px;
      background: #fff;
      position: relative;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);

      .kt-svg-icon.search-icon svg {
        fill: #bfbfbf;
        width: 20px;
      }

      .kt-quick-search__input {
        height: 45px;

        &::placeholder {
          font-weight: 400;
          color: #b7b7b7;
        }
      }

      .dropdown-menu-lg {
        width: 400px;
        border-radius: 0;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
        padding: 0.6rem;
      }
    }
  }
}

.kt-header__topbar .kt-header__topbar-item {
  margin-right: 1.8rem !important;
}

.kt-header__topbar-item.kt-header__topbar-item--user {
  .kt-header__topbar-user-chevron {
    span {
      transition: all 0.2s ease;
    }

    .up {
      display: none;
    }

    .down {
      display: inline-block;
    }
  }

  &.show {
    .kt-header__topbar-user-chevron {
      .up {
        display: inline-block;
      }

      .down {
        display: none;
      }
    }
  }

  .kt-header__topbar-user img {
    border-radius: 50% !important;
  }

  .kt-header__topbar-user .kt-header__topbar-user-chevron {
    color: #d9d9d9;
    margin-left: 15px;
    font-size: 18px;
  }

  .dropdown-menu {
    .kt-nav {
      .kt-nav__item {
        .kt-nav__link {
          padding: 0.55rem 3rem;

          .kt-nav__link-text {
            font-weight: 500;
            font-size: 1.2rem;

            .user-role {
              color: #a6a6a6;
              font-weight: 300;
              font-size: 1.1rem;
            }

            &.sign-out {
              color: #a6a6a6;
            }
          }
        }

        &.kt-nav__item--disabled {
          opacity: 1;

          .kt-nav__link {
            cursor: auto;
          }

          .kt-nav__link-text {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

.kt-header-base-light .kt-header .kt-header__topbar .kt-header__topbar-item {
  .kt-header__topbar-icon.quick-actions-navigation-icon {
    i {
      color: #fff !important;
      font-size: 1.15rem;
    }

    background-color: transparent !important;

    button {
      box-shadow: 0px 3px 2px -1px rgba(0, 0, 0, 0.05), 0px 6px 5px 0px rgba(0, 0, 0, 0.05),
        0px 1px 5px 0px rgba(0, 0, 0, 0.05);
    }
  }

  .quick-actions-navigation-dropdown {
    background-color: kt-get($kt-state-colors, brand, base);
    color: #fff !important;
    border-radius: 6px;

    .kt-nav__item {
      margin: 5px 0;

      .kt-nav__link {
        img.kt-nav__link-icon {
          width: 23px;
          margin-right: 15px;
          flex: 0 0 23px;
        }

        .kt-nav__link-text,
        .kt-nav__link-icon {
          color: #fff;
          font-weight: 600;
          font-size: 1.18rem;
          letter-spacing: 0.5px;
        }
      }

      &:hover {
        .kt-nav__link {
          background-color: lighten($color: kt-get($kt-state-colors, brand, base), $amount: 3%);
        }
      }
    }
  }
}

.kt-header__topbar-item.notifications-item {
  .kt-header__topbar-icon {
    svg {
      height: 30px;
      width: 30px;
      fill: #bfbfbf;
    }

    .mat-badge-content {
      min-width: 25px;
      height: 19px;
      line-height: 19px;
      top: -4px !important;
    }
  }

  .kt-notification__item {
    padding: 0.9rem 1.5rem;

    .kt-notification__item-details {
      flex-direction: row;
      justify-content: space-between;

      .kt-notification__item-title {
        color: #737373;
      }

      .kt-notification__item-time {
        font-size: 0.9rem;
        color: #a6a6a6;
      }
    }

    &.not-read {
      .kt-notification__item-title {
        font-weight: 600;
      }

      .kt-notification__item-time {
        font-weight: 500;
      }
    }

    &:after {
      display: none;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}

/***** General styles for dropdowns **/
.dropdown-menu.dropdown-menu-top-rounded {
  border-radius: 6px;
}

.dropdown.has-arrow {
  .dropdown-menu {
    .kt-nav,
    .dropdown-menu-content {
      &:before {
        display: block;
        content: "";
        position: absolute;
        border-right: 9px solid transparent;
        border-bottom: 9px solid #fff;
        border-left: 9px solid transparent;
        top: -9px;
        margin-left: 9px;
        width: 0;
        height: 0;
        right: 15px;
      }
    }

    .kt-nav:before {
      right: 55px !important;
    }
  }
}

.ellipses-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 99%;
  transition: all 0.2s ease;

  @media (max-width: 768px) {
    max-width: 200px;
  }

  &:hover {
    overflow: visible;
    // white-space: normal;
    word-wrap: break-word;
  }
}

/*** Dropzone ***/
.dropzone.dz-wrapper {
  border: 2px solid #ebebeb !important;
  border-radius: 4px;
  background-color: #ffffff !important;
}

.dropzone.dz-wrapper .dz-message {
  min-height: 75px !important;
}

.dropzone .dz-preview .dz-image {
  display: none !important;
}

.dropzone .dz-preview .dz-details {
  opacity: 1 !important;
  padding: 8px 16px !important;
  text-align: left !important;

  .dz-size {
    display: none !important;
  }

  .dz-filename {
    white-space: normal !important;
    overflow: visible !important;

    &:hover {
      span {
        border: none !important;
      }
    }
  }
}

.dropzone-borderless {
  .dropzone.dz-wrapper {
    border: none !important;
    min-height: 70px !important;
  }

  .dropzone.dz-wrapper .dz-message {
    min-height: 50px !important;
  }

  .dropzone .dz-preview {
    min-height: 60px;
  }
}

.dropzone.dz-wrapper .dz-message .dz-text .dropzone-placeholder {
  text-align: left;
  color: #cccccc;
  font-size: 15px;

  .file-icon {
    width: 18px;
    margin-right: 8px;
  }
}

.dropzone.dz-wrapper .dz-preview .dz-remove {
  text-align: left;

  i {
    color: #9a9a9a;
  }
}

.dropzone-row {
  .mat-form-field {
    top: 5px;

    .mat-form-field-infix .mat-select-value-text span {
      color: #636363 !important;
    }
  }

  border: 2px solid #ebebeb !important;

  &.uploaded {
    border-color: kt-get($kt-state-colors, brand, base) !important;
  }

  .remove-document {
    align-self: center;

    button {
      width: 25px;
      height: 32px;
      line-height: 32px;
      top: -5px;

      img {
        width: 18px;
      }
    }
  }

  .uploaded-file-info {
    height: 80px !important;
    align-items: center;
    display: flex;
    padding: 15px 10px;

    .file-icon {
      width: 18px;
      margin-right: 15px;
      height: 22.3px;
    }

    .file-info {
      .title {
        color: kt-get($kt-state-colors, brand, base) !important;
        margin-bottom: 0;
      }

      .file-name {
        color: #000;
        margin-bottom: 0;
      }
    }
  }
}

// Multipe upload
.dropzone.dz-multiple.dz-started {
  border: 0;
  padding: 0;

  .dz-message {
    display: none !important;
  }

  .dz-preview {
    min-width: 100px;
  }
}

.mat-tooltip.tooltip-info {
  padding: 15px;
  background-color: #ffffdb;
  color: #000000;
  max-width: 650px;
  white-space: pre-line;
}

.mat-tooltip.tooltip-info.tooltip-info-xl {
  padding: 30px 15px;
  font-size: 15px;
  line-height: 2.1;
}

.mat-tooltip.tooltip-info.tooltip-info-md {
  padding: 20px;
  min-width: 350px;
  font-size: 14px;
  font-weight: 400;
}

.mat-tooltip:not(.tooltip-info) {
  margin-bottom: 0;
}

/** Tabs **/
.mat-tab-group {
  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: 1px solid #e6e6e6;
  }

  .mat-tab-label {
    opacity: 1 !important;
    color: #a6a6a6;

    &.mat-tab-label-active {
      color: kt-get($kt-state-colors, brand, base) !important;

      .mat-tab-label-icon svg {
        fill: kt-get($kt-state-colors, brand, base) !important;
      }
    }

    .mat-tab-label-icon {
      width: 30px;

      svg {
        width: 20px;
        fill: #cccccc !important;
      }
    }
  }
}

/** Fonts **/
@font-face {
  font-family: "Gotham";
  src: url("assets/fonts/Gotham/Gotham-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Gotham";
  src: url("assets/fonts/Gotham/Gotham-Medium.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Gotham";
  src: url("assets/fonts/Gotham/Gotham-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Gotham";
  src: url("assets/fonts/Gotham/Gotham-Bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Gotham";
  src: url("assets/fonts/Gotham/Gotham-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Gotham";
  src: url("assets/fonts/Gotham/Gotham-Black.ttf") format("truetype");
  font-weight: 800;
}

body [class^="mat-"]:not(i):not(mat-icon),
body [class*=" mat-"]:not(i):not(mat-icon) {
  font-family: "Gotham";
}

@media (min-width: 1440px) {
  kt-base {
    position: relative;
    width: 1440px;
    margin: auto;

    .kt-header.kt-header--fixed {
      position: absolute !important;
    }

    .kt-aside--fixed.kt-aside {
      position: absolute !important;
    }
  }
}

.mat-autocomplete-panel .mat-option {
  font-size: 12px;
}

.mat-select-panel,
.mat-autocomplete-panel {
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: lighten($color: #d8dce6, $amount: 2%);
    border-radius: 4px;
    transition: background-color 0.2s linear;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #d8dce6;
  }
}

.dropdown-menu.dropdown-menu-xl,
.dropdown-menu.dropdown-menu-lg,
.dropdown-menu.dropdown-menu-md {
  @media (max-width: 768px) {
    width: 300px !important;
    transform: translate(-171px, 36px) !important;
  }
}

.vat-code-select {
  .mat-form-field-flex {
    border: 0 !important;
    pointer-events: none;
    display: initial !important;
  }

  .mat-select-arrow {
    border-left: 0;
    border-right: 0;
  }
}

.assigned-input .mat-form-field-wrapper {
  padding-bottom: 0;
}

.example-month-picker .mat-calendar-period-button {
  // pointer-events: none;
}

.example-month-picker .mat-calendar-arrow {
  // display: none;
}

// Custom styling of the details widgets
.custom-details-label {
  font-size: 10px;
  padding-left: 18px;
  color: $kt-table-tr-color;
  margin-bottom: 2px;
}

.custom-details-value {
  padding-left: 18px;
  color: $kt-table-td-color;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-details-row {
  margin-bottom: 10px;
}

// End style details widgets

// Set global styling of dashboard widget tables
.dashboard-table-date {
  width: 100px;
}

.dashboard-table-badge {
  width: 70px;
  text-align: left;
}

.dashboard-table-actions {
  width: 50px;
  text-align: right;
}

.dashboard-table-amount {
  width: 100px;
  text-align: left !important;
}

.no-filter-data {
  position: absolute;
  bottom: 0;
  left: 3px;
  top: 40px;
  font-size: 12px;
}

.mat-sort-header-content {
  text-align: left !important;
}

.mat-sort-header-arrow {
  color: #1868c8 !important;
  // background-color: red !important;
}

// End widget tables
