/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~material-design-icons-iconfont/dist/material-design-icons.css";
@import "~highlight.js/styles/googlecode.css";

// header base skins
.kt-header-base-light {
  @import "sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
  @import "sass/global/layout/header/skins/base/dark.scss";
}

// header menu skins
.kt-header-menu-light {
  @import "sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
  @import "sass/global/layout/header/skins/menu/dark.scss";
}

// brand skins
.kt-brand-dark {
  @import "sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "sass/global/layout/brand/skins/light.scss";
}

// aside skins
.kt-aside-dark {
  @import "sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "sass/global/layout/aside/skins/light.scss";
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
  min-width: 600px !important;
  width: 100% !important;
}

.position-static {
  position: static;
}

.mat-raised-button {
  border: none !important;
  font-weight: 500 !important;
}

/** Dropzone styles **/
.dropzone.dz-wrapper {
  min-height: 90px !important;
  background-color: #f9f9f9 !important;
  border: 2px dashed #aaa !important;
  margin: 5px !important;
}

.dropzone.dz-wrapper .dz-message {
  min-height: 90px !important;
  border: none !important;
  background-color: transparent !important;
}

.dropzone.dz-wrapper .dz-preview {
  background: transparent !important;
}

.dropzone.dz-wrapper .dz-preview .dz-image img {
  width: 80px !important;
  height: 80px !important;
  border-radius: 4px !important;
  margin: auto !important;
}

.dropzone.dz-wrapper .dz-preview .dz-progress {
  display: none;
}

.dropzone.dz-wrapper .dz-preview .dz-remove {
  position: relative;
  z-index: 999;
  padding: 5px 0;

  i {
    cursor: pointer;
  }
}

.dropzone-multi {
  .dz-preview {
    min-width: 100px;
  }

  .dz-started .dz-message {
    display: none !important;
  }
}

/** End Dropzone styles **/
.mat-dialog-container {
  padding: 0 !important;

  .kt-portlet {
    margin-bottom: 0 !important;
  }
}

.kt-mat-snackbar__close button i,
.kt-mat-alert__close button i {
  color: #fff;
}

.kt-portlet__head__custom {
  cursor: move;
}

.datatable-container {
  max-height: 719px;
  max-width: 100%;
  overflow: auto;
  z-index: 0;
}
